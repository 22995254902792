<script setup lang="ts">
import { type HeroBannerItem } from '@laam/cms-shared';
import SliderImageComponent from '../SliderImageComponent.vue';
import { shouldShowBanner } from './utils.ts';

interface Props {
	data: HeroBannerItem[];
	sectionIndex: number;
}

const { data } = defineProps<Props>();
const CountryStore = useCountryCode();
const { deliverToCountry } = storeToRefs(CountryStore);
const { storeType } = useGlobalStore();

const globalCutoffConfig = inject('globalCutoffConfig') as any;

const pakistanLocalDatesCheck = computed(
	() =>
		storeType === 'MARKETPLACE' &&
		deliverToCountry.value === 'PK' &&
		globalCutoffConfig &&
		globalCutoffConfig.value &&
		globalCutoffConfig.value?.local_dates,
);
const showBanner = computed(() => {
	const date = new Date();

	if (pakistanLocalDatesCheck.value) {
		// if date is greater than pan pak date
		if (
			date > new Date(globalCutoffConfig.value?.local_dates?.['pak_cutoff']) &&
			date < new Date(globalCutoffConfig.value?.local_dates?.['lhr_cutoff'])
		) {
			return true;
		}
		return false;
	}
	if (
		deliverToCountry.value !== 'PK' &&
		globalCutoffConfig &&
		storeType === 'MARKETPLACE'
	) {
		return shouldShowBanner(deliverToCountry.value, globalCutoffConfig);
	}
	return false;
});

const cdnImageUrls = ref({
	desktop: {
		default:
			'https://cdn.shopify.com/s/files/1/0618/4619/2349/files/delivered-before-eid-174168874952423.png',
		kli: 'https://cdn.shopify.com/s/files/1/2337/7003/files/free-shipping-174272145049278.png',
		lhr: 'https://cdn.shopify.com/s/files/1/2337/7003/files/free-shipping-lahore-only-174272136519496.png',
	},

	mobile: {
		default:
			'https://cdn.shopify.com/s/files/1/0618/4619/2349/files/delivered-before-eid-mobile-174168873089462.png',
		kli: 'https://cdn.shopify.com/s/files/1/2337/7003/files/delivered-before-eid-mobile-174272112150685.png',
		lhr: 'https://cdn.shopify.com/s/files/1/2337/7003/files/delivered-before-eid-mobile-lahore-only-174272061369205.png',
	},
});
const showBannerFor = computed(() => {
	if (pakistanLocalDatesCheck.value) {
		const date = new Date();
		const pakCutoff = new Date(
			globalCutoffConfig.value?.local_dates?.['pak_cutoff'],
		);
		const kliCutoff = new Date(
			globalCutoffConfig.value?.local_dates?.['kli_cutoff'],
		);
		const lhrCutoff = new Date(
			globalCutoffConfig.value?.local_dates?.['lhr_cutoff'],
		);
		if (date > pakCutoff) {
			if (date < kliCutoff) {
				return 'kli';
			} else if (date < lhrCutoff) {
				return 'lhr';
			}
		}
	}
	return 'default';
});

const cdnBannerImage = computed(() => {
	return {
		desktop_url:
			cdnImageUrls.value.desktop[
				showBannerFor.value as keyof typeof cdnImageUrls.value.desktop
			]!,
		link: {
			entity: 'hero-banner',
			href: '/trending-products',
			id: 0,
			name: 'Trending Products',
		},
		mobile_url:
			cdnImageUrls.value.mobile[
				showBannerFor.value as keyof typeof cdnImageUrls.value.mobile
			],
	};
});
const computedSlides = computed(() => {
	if (showBanner.value) {
		return [cdnBannerImage.value, ...data];
	}
	return data;
});
</script>
<template>
	<div class="">
		<Carousel
			class="hero-banner--carousel"
			:wrap-around="true"
			:autoplay="computedSlides.length === 1 ? false : 3000"
		>
			<Slide v-for="(item, index) in computedSlides" :key="item" :index="index">
				<SliderImageComponent
					:vertical-index="sectionIndex"
					:data="item"
					:horizontal-index="index"
				/>
			</Slide>

			<template #addons><pagination /> </template>
		</Carousel>
	</div>
</template>

<style>
.hero-banner--carousel .carousel__prev,
.hero-banner--carousel .carousel__next {
	@apply bg-white rounded-full top-[49%] p-md mx-3xl lg:p-lg lg:mx-5xl opacity-25;
}
.hero-banner--carousel .hero-banner--carousel__item {
	@apply rounded-xs text-xl w-full;
}

.hero-banner--carousel .carousel__slide {
	@apply p-none items-start flex justify-center gap-md h-fit rounded-xs;
}

.hero-banner--carousel .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.hero-banner--carousel .carousel__pagination-item {
	@apply box-border;
}

.hero-banner--carousel .carousel__pagination-button {
	@apply p-none;
}

.hero-banner--carousel .carousel__pagination-button::after {
	@apply bg-white rounded-full w-xl h-xs opacity-40;
}

.hero-banner--carousel .carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}
</style>
