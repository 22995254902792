<script setup lang="ts">
import type { HeroBannerItem } from '@laam/cms-shared';
import { useWindowScroll, watchDebounced, watchOnce } from '@vueuse/core';
import LazyImage from '~/components/LazyImage.vue';
import { vElementVisibility } from '@vueuse/components';
import { cn } from '@laam/lib/utils';
import { isMobileView } from '~/utils/helpers';

interface Props {
	data: HeroBannerItem;
	sectionIndex?: number;
	displayTimer?: boolean;
	endTime?: Date | null;
}

const { data, sectionIndex, displayTimer, endTime } = defineProps<Props>();

const { $cathodeClient, $eventClient } = useNuxtApp();

const { deviceType } = useDeviceType();

const imageUrl = computed(() => {
	return deviceType.value === 'desktop' ? data.desktop_url : data.mobile_url;
});

const isVisible = ref(false);

const { y } = useWindowScroll();
const hasWindowScrolled = ref(false);
watchOnce(y, () => {
	hasWindowScrolled.value = true;
});

function onElementVisibility(state: boolean) {
	isVisible.value = state;
}

const timeLeft = ref<{
	days: string;
}>({
	days: '-',
});

const normalizeLAAMUrls = (url: string) => {
	if (url.includes('https://laam.pk')) {
		return url.replace('https://laam.pk', '');
	}
	return url;
};

const difference = ref();

const updateTimeLeft = () => {
	const now = new Date();
	if (endTime !== null) {
		difference.value = endTime?.getTime()! - now.getTime();
	}

	if (difference.value <= 0) {
		timeLeft.value = {
			days: '0',
		};
	} else {
		const days = Math.floor(
			difference.value / (1000 * 60 * 60 * 24) + 1,
		).toString();

		timeLeft.value = {
			days: days,
		};
	}
};

const formattedDate = () => {
	if (endTime == null) {
		return null;
	}
	const date = new Date(endTime);
	const month = date.toLocaleString('en-US', { month: 'long' });
	const day = date.getDate();
	const getOrdinalSuffix = (day: number) => {
		switch (day % 10) {
			case 1:
				return 'st';
			case 2:
				return 'nd';
			case 3:
				return 'rd';
			default:
				return 'th';
		}
	};

	return `${day}${getOrdinalSuffix(day)} ${month}`;
};

onMounted(() => {
	updateTimeLeft();
	const timeInterval = setInterval(updateTimeLeft, 3600000);
	onBeforeUnmount(() => {
		clearInterval(timeInterval);
	});
});

watchDebounced(
	isVisible,
	(value) => {
		if (value) {
			$cathodeClient.queueImpression('entity_impression', {
				data: [
					{
						href: normalizeLAAMUrls(data.link.href),
						data_string: JSON.stringify(data),
						title: data.link.name ?? '',
						image:
							deviceType.value === 'mobile'
								? data.mobile_url
								: data.desktop_url,
					},
				],
				vertical_index: sectionIndex!,
				method: 'static',
				entity: '',
				heading: '',
				component: 'mini-banner',
				scroll_position: y.value,
				source: window.location.href,
				type: hasWindowScrolled.value ? 'scroll_impression' : 'load_impression',
				time: new Date().toUTCString(),
				data_source: 'manual',
				clicked_from: 'homepage',
				page: 'homepage',
			});
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);

function handleClickEvent() {
	// Cathode event
	$cathodeClient.queueEvent('entity_click', {
		source: normalizeLAAMUrls(window.location.href),
		page: 'homepage',
		href: data.link.href,
		clicked_from: 'homepage',
		image: deviceType.value === 'mobile' ? data.mobile_url : data.desktop_url,
		title: data.link.name ?? '',
		entity: '',
		horizontal_index: 0,
		vertical_index: sectionIndex!,
		slotted: false,
		heading: '',
		component: 'mini-banner',
		product_count: null,
		sale_percentage: 0,
		scroll_position: y.value,
		data_source: 'manual',
		data_string: JSON.stringify(data),
	});
	// statsig event
	$eventClient.sendEvent('homepage-entity-click', {
		to: data.link.href,
		index: sectionIndex!,
		title: '',
		component: 'mini-banner',
		entity: 'mini-banner',
		heading: '',
		horizontalIndex: 0,
		url: window.location.href,
	});
}
</script>
<template>
	<div>
		<NuxtLink
			v-element-visibility="[onElementVisibility, { threshold: 0.8 }]"
			:to="normalizeLAAMUrls(data.link.href)"
			as="button"
			:disabled="data.link.href === 'https://laam.pk'"
			:class="
				cn('block w-full lg:rounded-3xl', {
					relative: displayTimer,
				})
			"
			@click="handleClickEvent()"
		>
			<LazyImage
				class="homepage__image-slider-image lg:rounded-3xl"
				:src="imageUrl"
				:alt="imageUrl"
				:width="deviceType === 'mobile' ? 250 : 1032"
				loading="eager"
			/>
			<div
				v-if="displayTimer"
				class="md:max-h-w-[500px] md:w-[330px] flex flex-row gap-xs absolute md:top-[20px] md:mr-[20px] right-0 md:float-right"
				:class="{
					'top-[40%] w-full justify-center right-0': isMobileView(),
				}"
			>
				<div
					class="rounded-small flex justify-center items-center flex-col"
					:class="{ 'mr-[5px]': timeLeft.days === '0' }"
				>
					<p
						class="text-semibold md:text-3xl text-center text-white font-bold"
						:class="{ 'text-xl': isMobileView() }"
					>
						{{ timeLeft.days }}
						{{ timeLeft.days < '2' ? 'Day' : 'Days' }} Left
					</p>
					<p
						class="text-semibold md:text-2xl text-center text-white font-semibold"
						:class="{ 'text-md': isMobileView() }"
					>
						Order by - {{ formattedDate() }}
					</p>
				</div>
			</div>
		</NuxtLink>
	</div>
</template>
