import { parseISO, isAfter } from 'date-fns';
export function shouldShowBanner(
	countryCode: string,
	globalCutoffConfig: Record<string, any>,
): boolean {
	if (!globalCutoffConfig.value) {
		return false;
	}
	const today = new Date();
	let countryData = globalCutoffConfig.value?.[countryCode];
	if (!countryData) {
		countryData = globalCutoffConfig.value?.['rest_of_world'];
		return isAfter(parseISO(countryData), today);
	}
	if (countryCode === 'IN') {
		return isAfter(parseISO(countryData.skynet), today);
	}

	return isAfter(parseISO(countryData.dhl), today);
}
