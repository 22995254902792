import {
	pageSectionsSchema,
	previewPageSectionsSchema,
	type PageSectionResponse,
} from '@laam/cms-shared';
import { validatorFactory, validatorFactoryArray } from '@laam/lib/validator';
import axios from 'axios';

const validator = validatorFactory<PageSectionResponse>(pageSectionsSchema);
const previewValidator = validatorFactoryArray<PageSectionResponse['sections']>(
	previewPageSectionsSchema,
);

const internationalIdentifierMapping = {
	homepage: 'homepage-international',
	women: 'women-international',
};

export const fetchSections = async (
	pageRequestId: string,
	identifier: string,
	cursor?: string,
	countryCode?: string,
	storeType?: string,
) => {
	const url = new URL(useRuntimeConfig().public.apiBaseUrl + '/v1/store/page');
	const stableId = useCookie('statsig-stable-id');
	if (countryCode && storeType) {
		if (
			countryCode !== 'PK' &&
			storeType === 'MARKETPLACE' &&
			(identifier === 'homepage' || identifier === 'women')
		) {
			identifier = internationalIdentifierMapping[identifier];
		}
	}
	url.searchParams.append('identifier', identifier);
	if (cursor) {
		url.searchParams.append('cursor', cursor);
	}
	const startTime = Date.now();
	const response = await axios.get(url.toString(), {
		headers: {
			'statsig-stable-id': stableId.value!,
			'page-request-id': pageRequestId,
			'store-identifier': getStoreIdentifier()!.toString(),
		},
	});
	const endTime = Date.now();
	if (response.status >= 200 || response.status <= 300) {
		const data = await response.data;
		try {
			return {
				...validator.verify(data),
				pageRequestId: pageRequestId,
				latency: endTime - startTime,
			};
		} catch (error) {
			console.log('Failed to fetch or validate sections', error);
			throw new Error('Failed to fetch sections');
		}
	} else {
		throw new Error('Failed to fetch sections');
	}
};

export const fetchPreviewSections = async (identifier: string) => {
	const url = new URL(
		useRuntimeConfig().public.apiBaseUrl + '/v1/store/page/preview',
	);
	url.searchParams.append('identifier', identifier);

	const api = createApiInstance();
	const response = await api.get(url.toString());

	if (response.status === 200) {
		try {
			const data = await response.data;
			return previewValidator.verify(data);
		} catch (error) {
			console.log('Failed to fetch or validate sections', error);
			throw new Error('Failed to fetch sections');
		}
	}
	throw new Error('Failed to fetch sections');
};
