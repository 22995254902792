<script setup lang="ts">
import {
	isLinkNode,
	mapCategoriesToLinkNodes,
	type CategoriesResponse,
	type EntityData,
} from '@laam/cms-shared';
import CategoryCard from './CategoryCard.vue';
import { computed, inject, ref, type Ref } from 'vue';

interface Props {
	metadata: EntityData;
	data: CategoriesResponse;
	sectionIndex: number;
}

const { data, metadata } = defineProps<Props>();

const output = computed(() => {
	if ('items' in metadata.input) {
		// check if data is link nodes array
		if (isLinkNode(data)) {
			return data;
		}
		return mapCategoriesToLinkNodes(data, metadata.input);
	} else return [];
});

const deviceType = inject('deviceType') as Ref<string | undefined>;

const categoryCarouselRef = ref();
</script>

<template>
	<div class="gap-3xl lg:gap-7xl lg:p-0 p-3xl pb-0 flex flex-col items-center">
		<Carousel
			ref="categoryCarouselRef"
			class="cms--carousel-categories-scrollable w-full"
			:items-to-show="deviceType === 'mobile' ? 4.4 : 7"
			snap-align="start"
		>
			<Slide
				v-for="(card, horizontalIndex) in output"
				:key="horizontalIndex"
				:index="horizontalIndex"
			>
				<slot :entity="metadata.entity" :data="card" :index="horizontalIndex">
					<CategoryCard
						:entity="metadata.entity"
						:data="card"
						:index="horizontalIndex"
						shape="square"
						class="pr-md"
					/>
				</slot>
			</Slide>
			<template #addons>
				<Navigation v-if="deviceType !== 'mobile'" />
			</template>
		</Carousel>
	</div>
</template>

<style>
.cms--carousel-categories-scrollable .carousel__prev {
	@apply bg-white rounded-full top-[40%] translate-y-[-40%]  mx-3xl lg:mx-5xl [&_svg]:!size-3xl;
}

.cms--carousel-categories-scrollable .carousel__next {
	@apply bg-white rounded-full top-[40%] translate-y-[-40%]  mx-3xl lg:mx-5xl [&_svg]:!size-3xl;
}

.cms--carousel-categories-scrollable
	.cms--carousel-categories-scrollable__item {
	@apply relative rounded-xs text-xl text-white bg-white w-full;
}

.cms--carousel-categories-scrollable .carousel__slide {
	@apply pr-md lg:pr-md items-start;
}

.cms--carousel-categories-scrollable .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.cms--carousel-categories-scrollable .carousel__pagination-item {
	@apply box-border;
}

.cms--carousel-categories-scrollable .carousel__pagination-button {
	@apply p-none;
}

.cms--carousel-categories-scrollable .carousel__pagination-button::after {
	@apply bg-white rounded-2xl w-10xl h-xs opacity-40;
}

.cms--carousel-categories-scrollable
	.carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}
</style>
